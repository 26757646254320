import React from 'react';
import Layout from 'components/Layout';
import styled from 'styled-components';
import SEO from 'components/Seo';
import { graphql } from 'gatsby';
import { Title } from 'ui';
import ButtonLink from 'components/ButtonLink';
import FaqSearchAndResults from 'components/FaqSearchAndResults';
import { Stack, media } from '@tymate/margaret';

const Content = styled.div`
  background-color: #fff;
  padding: ${({ theme }) => theme.spacing(2)};
  border-top: 8px solid ${({ theme }) => theme.yellow};
  width: 48rem;
  max-width: 100%;
  min-height: 33.75rem;
  margin-left: auto;
  margin-right: auto;
  text-align: center;
  box-shadow: 0 50px 100px 0 ${({ theme }) => theme.separator};

  ${media.tablet`
    margin-top: ${({ theme }) => theme.spacing(8)};
    margin-bottom: ${({ theme }) => theme.spacing(6)};
  `}
`;

const Label = styled.div`
  color: ${({ theme }) => theme?.textLighter};
  width: 30rem;
  max-width: 100%;
  margin-left: auto;
  margin-right: auto;
`;

const Faq = ({ data: { datoCmsPark, allDatoCmsPark } = {}, pageContext }) => {
  const {
    title,
    description,
    content,
    filters,
    seo,
    help,
    ctaUrl,
    ctaName,
    ctaOfferUrl,
    ctaOfferName,
  } = datoCmsPark?.faq || {};

  return (
    <Layout
      headerVariant="light"
      ctaName={datoCmsPark?.ctaName}
      ctaUrl={datoCmsPark?.ctaUrl}
      ctaEventName={datoCmsPark?.ctaEventName}
      isExternalLink
      ctaOfferUrl={ctaOfferUrl}
      ctaOfferName={ctaOfferName}
      datoCmsPark={datoCmsPark}
      allDatoCmsPark={allDatoCmsPark}
      urlsTranslations={pageContext?.urlsTranslations}
      menuLandingPages={pageContext?.menuLandingPages}
      parkSlug={pageContext?.parkSlug}
    >
      <SEO
        title={seo?.title}
        description={seo?.description}
        jsonLdBreadcrumb={pageContext?.breadcrumbList}
        meta={
          datoCmsPark?.slug === 'koezio-lille'
            ? [
                {
                  name: 'robots',
                  content: 'index, follow',
                },
              ]
            : [{ name: 'robots', content: 'noindex, follow' }]
        }
      />

      <Content>
        <Title>{title}</Title>
        <Label>{description}</Label>
        <FaqSearchAndResults content={content} filters={filters} />
        <Stack direction="column" alignX="center" gutterSize={1} marginTop={2}>
          <Label>{help}</Label>
          <div>
            <ButtonLink
              color="#FFD618"
              style={{ color: '#000' }}
              href={ctaUrl}
              rel="noopener noreferrer nofollow"
            >
              {ctaName}
            </ButtonLink>
          </div>
        </Stack>
      </Content>
    </Layout>
  );
};

export const query = graphql`
  query ($locale: String!, $parkSlug: String!) {
    datoCmsPark(locale: { eq: $locale }, slug: { eq: $parkSlug }) {
      ...ParkLayoutContent
      faq {
        title
        description
        content {
          question
          answerNode {
            childMarkdownRemark {
              id
              html
            }
          }
          filters {
            id
            name
          }
        }
        filters {
          id
          name
        }
        isExternalLink
        ctaName
        ctaUrl
        ctaOfferUrl
        ctaOfferName
        help
        seo {
          title
          description
        }
      }
    }
    allDatoCmsPark(
      filter: { locale: { eq: $locale } }
      sort: { fields: order, order: ASC }
    ) {
      nodes {
        ...ParkLayoutContent
      }
    }
  }
`;

export default Faq;
